// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, optimizeAppear, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["BYezRfs6n", "wpnpcn728", "T094WiuYS"];

const serializationHash = "framer-gdR3j"

const variantClassNames = {BYezRfs6n: "framer-v-10e49z7", T094WiuYS: "framer-v-1xvshbx", wpnpcn728: "framer-v-1y8spcb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 150, y: 0}

const transition2 = {delay: 1, duration: 0.5, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Custom: "T094WiuYS", Image: "BYezRfs6n", Video: "wpnpcn728"}

const getProps = ({appear, height, id, image, poster, width, youtubeHome, ...props}) => { return {...props, hkER3s2E3: image ?? props.hkER3s2E3 ?? {src: "https://framerusercontent.com/images/49V99o2SHCUWiNXP15Sk9otAY.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/49V99o2SHCUWiNXP15Sk9otAY.jpg?scale-down-to=1024 576w,https://framerusercontent.com/images/49V99o2SHCUWiNXP15Sk9otAY.jpg 882w"}, sYZJ45msD: appear ?? props.sYZJ45msD, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BYezRfs6n", vgbO8RJKr: youtubeHome ?? props.vgbO8RJKr ?? {src: "https://framerusercontent.com/images/TYEfD43mI0SVj6i2c3QgmtYcHyQ.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/TYEfD43mI0SVj6i2c3QgmtYcHyQ.png?scale-down-to=1024 538w,https://framerusercontent.com/images/TYEfD43mI0SVj6i2c3QgmtYcHyQ.png 620w"}, w02mljpJa: poster ?? props.w02mljpJa ?? {src: "https://framerusercontent.com/images/E5e0QHF8clKE0E56ZQpk4mhTET8.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/E5e0QHF8clKE0E56ZQpk4mhTET8.jpg?scale-down-to=1024 576w,https://framerusercontent.com/images/E5e0QHF8clKE0E56ZQpk4mhTET8.jpg 720w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};youtubeHome?: {src: string; srcSet?: string};poster?: {src: string; srcSet?: string};appear?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hkER3s2E3, vgbO8RJKr, w02mljpJa, sYZJ45msD, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BYezRfs6n", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaphembfc = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("wpnpcn728")
})

const onAppearf382t5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2000)
})

const onAppear1pblkyb = activeVariantCallback(async (...args) => {
if (sYZJ45msD) {await delay(() => {}, 1000);
const res = await sYZJ45msD(...args);
if (res === false) return false;}
await delay(() => setVariant(CycleVariantState), 3000)
})

const onAppear1cwyrli = activeVariantCallback(async (...args) => {
if (sYZJ45msD) {await delay(() => {}, 500);
const res = await sYZJ45msD(...args);
if (res === false) return false;}
await delay(() => setVariant(CycleVariantState), 3000)
})

useOnVariantChange(baseVariant, {default: onAppearf382t5, T094WiuYS: onAppear1cwyrli, wpnpcn728: onAppear1pblkyb})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFX {...restProps} {...gestureHandlers} __framer__presenceAnimate={optimizeAppear("animate", "10e49z7", animation, undefined)} __framer__presenceInitial={optimizeAppear("initial", "10e49z7", animation1, undefined)} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", pixelHeight: 1456, pixelWidth: 816, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(vgbO8RJKr)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10e49z7", className, classNames)} data-framer-appear-id={"10e49z7"} data-framer-name={"Image"} data-highlight layoutDependency={layoutDependency} layoutId={"BYezRfs6n"} onTap={onTaphembfc} ref={ref ?? ref1} style={{borderBottomLeftRadius: 36, borderBottomRightRadius: 36, borderTopLeftRadius: 36, borderTopRightRadius: 36, ...style}} {...addPropertyOverrides({T094WiuYS: {__framer__presenceAnimate: optimizeAppear("animate", "1xvshbx", animation, undefined), __framer__presenceInitial: optimizeAppear("initial", "1xvshbx", animation1, undefined), "data-framer-appear-id": "1xvshbx", "data-framer-name": "Custom", background: {alt: "", fit: "stretch", pixelHeight: 1180, pixelWidth: 620, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(hkER3s2E3), ...{ positionX: "center", positionY: "center" }}}, wpnpcn728: {__framer__presenceAnimate: optimizeAppear("animate", "1y8spcb", animation, undefined), __framer__presenceInitial: optimizeAppear("initial", "1y8spcb", animation1, undefined), "data-framer-appear-id": "1y8spcb", "data-framer-name": "Video", background: {alt: "", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(w02mljpJa), ...{ positionX: "center", positionY: "center" }}, onTap: undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gdR3j.framer-4whaq2, .framer-gdR3j .framer-4whaq2 { display: block; }", ".framer-gdR3j.framer-10e49z7 { cursor: pointer; height: 724px; overflow: hidden; position: relative; width: 334px; will-change: var(--framer-will-change-override, transform); }", ".framer-gdR3j.framer-v-1y8spcb.framer-10e49z7 { cursor: unset; }", ".framer-gdR3j.framer-v-1xvshbx.framer-10e49z7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; justify-content: center; padding: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gdR3j.framer-v-1xvshbx.framer-10e49z7 { gap: 0px; } .framer-gdR3j.framer-v-1xvshbx.framer-10e49z7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-gdR3j.framer-v-1xvshbx.framer-10e49z7 > :first-child { margin-left: 0px; } .framer-gdR3j.framer-v-1xvshbx.framer-10e49z7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 724
 * @framerIntrinsicWidth 334
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wpnpcn728":{"layout":["fixed","fixed"]},"T094WiuYS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"hkER3s2E3":"image","vgbO8RJKr":"youtubeHome","w02mljpJa":"poster","sYZJ45msD":"appear"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerkqf0DZOGP: React.ComponentType<Props> = withCSS(Component, css, "framer-gdR3j") as typeof Component;
export default Framerkqf0DZOGP;

Framerkqf0DZOGP.displayName = "Screen Content";

Framerkqf0DZOGP.defaultProps = {height: 724, width: 334};

addPropertyControls(Framerkqf0DZOGP, {variant: {options: ["BYezRfs6n", "wpnpcn728", "T094WiuYS"], optionTitles: ["Image", "Video", "Custom"], title: "Variant", type: ControlType.Enum}, hkER3s2E3: {__defaultAssetReference: "data:framer/asset-reference,49V99o2SHCUWiNXP15Sk9otAY.jpg?originalFilename=framerphone-bg.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, vgbO8RJKr: {__defaultAssetReference: "data:framer/asset-reference,TYEfD43mI0SVj6i2c3QgmtYcHyQ.png?originalFilename=youtube-home.png&preferredSize=auto", title: "Youtube-Home", type: ControlType.ResponsiveImage}, w02mljpJa: {__defaultAssetReference: "data:framer/asset-reference,E5e0QHF8clKE0E56ZQpk4mhTET8.jpg?originalFilename=poster.jpg&preferredSize=auto", title: "Poster", type: ControlType.ResponsiveImage}, sYZJ45msD: {title: "Appear", type: ControlType.EventHandler}} as any)

addFonts(Framerkqf0DZOGP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})